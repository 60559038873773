import React from "react"
import DynamicComponent from "../components/DynamicComponent"
import Layout from "../components/Layout"
import SEO from "../components/Layout/SEO"


import useStoryblok from "../utils/storyblok"


export default function BlogEntry({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location)

  return (
    <Layout location={location}>
      <SEO meta={story.content.meta} locationPath={location.pathname} />
      <DynamicComponent
        blok={story}
        isBlogEntry={true}
        key={story._uid}
      />
    </Layout>
 
  )
}
